<template>
  <div>
    <general-table
      ref="cachoutTable"
      :api-url="APIURL"
      :add-type="addType"
      :view-content="viewContent"
      :type="type"
      :columns="columns"
      :edit-content="editContent"
      :delete-content="deleteContent"
      guard="friends"
    >
      <template #cell(created_at)="data">
        {{ timeFormat(data.item.created_at) }}
      </template>
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
          class="text-capitalize btn"
          @click="changeCashoutStatus(data.item)"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
    </general-table>
    <div>
      <b-modal
        id="cashoutStatus"
        ref="genmodal"
        hide-footer
        centered
        no-close-on-backdrop
        title="Change status"
      >
        <change-cahout-status
          :types="types"
          :user-form="userForm"
          :loader="loader"
          @changecCashoutACtive="changeCheckoutACtive"
          @closeModal="closeModal"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import moment from 'moment'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import timeAgo from '@/global-compositions/timeAgo'
import ChangeCahoutStatus from './ChangeCahoutStatus.vue'

export default {
  components: { GeneralTable, ChangeCahoutStatus },
  data() {
    return {
      APIURL: 'cashouts',
      addType: '',
      addComponentName: 'add-friend',
      editComponent: 'edit-friend',
      viewContent: false,
      editContent: true,
      deleteContent: false,
      type: 'modal',
      columns: [
        { key: 'id' },
        { key: 'amount', label: 'Amount' },
        { key: 'merchant_name', label: 'Merchant' },
        { key: 'cashout_type', label: 'Cashout Type' },
        { key: 'status', label: 'Status' },
        { key: 'created_at', label: 'Date' },
        // { key: 'actions' },
      ],
    }
  },
  setup() {
    const resolveUserStatusVariant = status => {
      if (status === 'pending') return 'warning'
      if (status === 'approved') return 'success'
      if (status === 'failed') return 'info'
      return 'danger'
    }
    const userForm = ref({})
    const loader = ref(false)
    const types = ref({})
    const activeType = [
      { text: 'Approved', value: 'approved' },
      { text: 'Refused', value: 'refused' },
      { text: 'Pending', value: 'Pending' },
      { text: 'Failed', value: 'failed' },
    ]

    return {
      resolveUserStatusVariant,
      timeAgo,
      types,
      activeType,
      userForm,
      loader,
    }
  },
  methods: {
    timeFormat(date) {
      return moment(date).format('ddd DD MMM YYYY HH:mm:ss')
    },
    changeCashoutStatus(data) {
      this.userForm = data
      this.filterTypes(data.status)
      this.$bvModal.show('cashoutStatus')
    },
    filterTypes(type) {
      const filteredType = this.activeType.filter(item => item.text !== type)
      this.types = filteredType
    },
    closeModal() {
      this.$bvModal.hide('cashoutStatus')
    },
    async changeCheckoutACtive() {
      this.loader = true
      try {
        const response = await axios.put(`cashouts/${this.userForm.id}`, this.userForm)
        if (response.status === 200 || response.status === 201) {
          this.closeModal()
          this.$refs.cachoutTable.getAllData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      } catch (error) {
        if (error.response.status === 422) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style>

</style>
