<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-form-group label="Activation Types">
          <b-form-select
            v-model="userForm.status"
            value-field="value"
            text-field="text"
            :options="types"
            right
          >
            <template #first>
              <b-form-select-option
                disabled
                :value="userForm.status"
              >
                {{ userForm.status }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-button
          v-if="!loader"
          variant="primary"
          class="mr-1"
          @click="changecCashoutACtive()"
        >
          Save
        </b-button>
        <b-button
          variant="outline-danger"
          class="closeModal"
          @click.prevent="closeModal()"
        >
          Cancel
        </b-button>
        <b-button
          v-if="loader"
          variant="primary"
          disabled
          class="mr-1"
        >
          <b-spinner
            small
            type="grow"
          />
          Loading...
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  props: {
    types: {
      type: Array,
      required: true,
    },
    userForm: {
      type: Object,
      required: true,
    },
    loader: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    changecCashoutACtive() {
      this.$emit('changecCashoutACtive')
    },
  },
}
</script>

<style>

</style>
